import React from 'react';
import camelcase from 'camelcase';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import additionalServicesAdsHook from 'hooks/additional-service-ads/additional-service-ads.hook';
import { Props } from './AdditionalServiceAds.interface';

import s from './AdditionalServiceAds.scss';

export default ({ slug, wide }: Props): JSX.Element => {
    const data = additionalServicesAdsHook();
    const ads = data.filter(({ node }) => node.pages.some((page) => page.slug === slug));
    const adsWide = data.filter(({ node }) => node.slug === slug);
    const serviceAds = !wide ? ads : adsWide;
    const getGradient = (colors: string) => {
        switch (colors) {
        case 'blue-purple': return 'linear-gradient(51deg, rgb(40,132,184) 0%, rgb(209,186,210) 100%)';
        case 'blue-green': return 'linear-gradient(51deg, rgb(62,173,207) 0%, rgb(171,233,205) 100%)';
        case 'pink-purple': return 'linear-gradient(315deg, #f9c5d1 0%, #9795ef 74%)';
        default: return 'linear-gradient(51deg, rgba(99, 164, 255, 0.8) 0%, rgba(131, 234, 241, 1) 100%)';
        }
    };
    const getSuffix = (slug: string) => {
        switch (slug) {
        case 'business-telephone-answering': return 'per month';
        case 'full-company-secretary-service': return 'per year';
        case 'london-registered-office-services': return 'per year';
        case 'service-address-service': return 'per year';
        case 'business-address-service' : return 'per year';
        default: return '';
        }
    };
    const getPrefix = (slug: string) => {
        switch (slug) {
        case 'our-company-formation-packages': return 'from';
        default: return '';
        }
    };

    return (
        <>
            <div className={`${s.additionalServices} ${wide ? s.additionalServices___wide : ''}`}>
                {serviceAds.map(({ node }): JSX.Element => {
                    const buttonStyle = s[ `additionalServices__button___${camelcase(node.gradiant_colors)}` ];
                    return (
                        <div key={node.title} className={s.additionalServices__service}>
                            <div
                                className={s.additionalServices__inner}
                                style={{ backgroundImage: getGradient(node.gradiant_colors) }}
                            >
                                <div className={s.additionalServices__content}>
                                    <div className={s.additionalServices__left}>
                                        <h3><Markdown source={node.title} /></h3>
                                        <Markdown source={node.body} container />
                                    </div>
                                    <div className={s.additionalServices__right}>
                                        { wide && (
                                            <span className={s.additionalServices__price}>
                                                {node.price && `${getPrefix(slug)} £${node.price.value} ${getSuffix(slug)}`}
                                            </span>)}
                                        <ButtonGroup block>
                                            <Button
                                                className={`${s.additionalServices__button} ${buttonStyle}`}
                                                block
                                                role="secondary"
                                                to={node.link_url}
                                            >
                                                Learn More
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <i
                                    className={s.additionalServices__icon}
                                    style={{ backgroundImage: `url(${node.icon.data.full_url})` }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
