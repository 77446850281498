import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusServicesAds, ServiceAd } from './additional-service-ads.interface';

export default (): ServiceAd[] => {
    const { allDirectusServicesAd } = useStaticQuery<AllDirectusServicesAds>(graphql`
		query {
			allDirectusServicesAd {
				edges {
					node {
						title
						body
						slug
						gradiant_colors
						link_url
						price {
							value
						}
						pages {
							title
							description
							slug
						}
					  icon {
						  data {
							  full_url
							}
						}
					}
				}
			}
		}
	`);

    return allDirectusServicesAd.edges;
};
